<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card v-if="pageDetails">
      <div class="mb-3 w-100 d-flex align-items-center justify-content-between">
        <h1 v-if="pageDetails">تنظیمات سئوی برگه {{ pageDetails.title }}</h1>
      </div>
      <b-row>
        <b-col cols="12" md="6">
          <b-form @submit.prevent>
            <b-form-group label="عنوان سئو" label-for="seoTitle">
              <b-form-input
                id="seoTitle"
                trim
                placeholder="درباره ما یا ..."
                v-model="pageDetails.seoTitle"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" md="6">
          <b-form @submit.prevent>
            <b-form-group label="آدرس سئو" label-for="seourl">
              <b-form-input
                id="seourl"
                trim
                placeholder="درباره ما یا ..."
                v-model="pageDetails.seourl"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="توضیحات سئو" label-for="seoDescription">
              <b-form-textarea
                id="seoDescription"
                trim
                placeholder="درباره ما یا ..."
                v-model="pageDetails.seoDescription"
              />
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!--- serp Desktop --->
          <div
            class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end"
          >
            <div class="serp-title">
              <h4 style="color: rgb(26, 13, 171) !important">
                {{ pageDetails.seoTitle }}
              </h4>
            </div>
            <div class="serp-url">
              <h5
                class="d-flex align-items-center flex-wrap"
                style="color: rgb(0, 102, 33) !important"
              >
                <span style="overflow-wrap: anywhere"
                  >{{ pageDetails.seourl.replaceAll(" ", "-") }}/</span
                >
                <span>
                  {{ "https://razi.oregonserver.com" }}
                  <span class="serp-arrow"></span>
                </span>
              </h5>
            </div>
            <div class="serp-description">
              <h6 style="overflow-wrap: anywhere">
                {{ pageDetails.seoDescription }}
              </h6>
            </div>
          </div>
          <!--- serp Mobile --->
          <div
            class="serp-preview d-lg-none p-3 d-flex flex-column align-items-end"
          >
            <div class="serp-title">
              <h6>
                {{ pageDetails.seoTitle }}
              </h6>
            </div>
            <div class="serp-url d-flex align-items-center flex-wrap">
              <small>{{ pageDetails.seourl.replaceAll(" ", "-") }}/</small>
              <small>
                {{ "https://razi.oregonserver.com" }}
                <small class="serp-arrow"></small>
              </small>
            </div>
            <div class="serp-description">
              <small>
                {{ pageDetails.seoDescription }}
              </small>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="primary" @click="sendPageUpdateRequest">
          بروزرسانی
        </b-button>
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "تنظیمات سئوی برگه",
  mounted() {
    this.getASinglePage();
  },
  data() {
    return {
      isLoading: false,
      pageDetails: null,
    };
  },
  methods: {
    async getASinglePage() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASinglePage = new GetASinglePage(_this);
        getASinglePage.setRequestParam({
          id: _this.$route.params.id,
        });
        await getASinglePage.fetch((response) => {
          if (response.isSuccess) {
            _this.pageDetails = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendPageUpdateRequest() {
      try {
        this.isLoading = true;
        if (
          !this.pageDetails.link.startsWith("/") ||
          !this.pageDetails.seourl.startsWith("/")
        ) {
          this.pageDetails.seourl = `/${this.pageDetails.seourl}`;
          this.pageDetails.link = this.pageDetails.seourl;
        } else {
          this.pageDetails.link = this.pageDetails.seourl;
        }
        let _this = this;
        let updateASinglePage = new UpdateASinglePage(_this);
        updateASinglePage.setRequestParamDataObj(_this.pageDetails);
        await updateASinglePage.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `سئوی برگه با موفقیت ویرایش شد.`,
              },
            });
            _this.getASinglePage();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
  },
};
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import { GetASinglePage, UpdateASinglePage } from "@/libs/Api/Page";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
</script>
<style scoped>
.serp-arrow {
  border-bottom-color: rgb(0, 102, 33);
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-left-color: rgba(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 4px;
  border-right-color: rgba(0, 0, 0, 0);
  border-right-style: solid;
  border-right-width: 4px;
  border-top-color: rgb(0, 102, 33);
  border-top-style: solid;
  border-top-width: 5px;
  color: rgb(128, 128, 128);
  cursor: default;
  font-family: arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  height: 0px;
  position: absolute;
  line-height: 27px;
  margin-left: 3px;
  margin-top: 6px;
  text-align: center;
  user-select: none;
  visibility: visible;
  white-space: nowrap;
  width: 0px;
}

.serp-title {
  color: rgb(26, 13, 171) !important;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 21.6px;
  text-align: left;
  text-decoration: none;
  visibility: visible;
  white-space: nowrap;
}

.serp-url {
  color: rgb(0, 102, 33) !important;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  text-align: left;
  visibility: visible;
  white-space: nowrap;
}

.serp-description {
  color: rgb(84, 84, 84) !important;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18.2px;
  text-align: left;
  visibility: visible;
  word-wrap: break-word;
}
</style>
